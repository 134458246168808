// @flow

import React, { useEffect } from 'react'
import { commitMutation, graphql, useRelayEnvironment } from 'react-relay'
import { useRouter } from 'found'

import { useRouterReset } from 'services/routeConfig/Router'
import { LoadingSpinner } from 'care-ui'

const mutation = graphql`
  mutation SwitchTenantPageMutation($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      user {
        homepage_path # required for redirect after change role
      }
    }
  }
`

export default function() {
  const environment = useRelayEnvironment()
  const resetRouter = useRouterReset()
  const { router, match: { params: { tenantId } } } = useRouter()

  useEffect(() => {
    function onCompleted({ userUpdate }) {
      if (userUpdate) {
        resetRouter()
        window.location.href = userUpdate.user.homepage_path
      }
    }

    const variables = {
      input: {
        user: {
          tenant_id: tenantId,
        },
      },
    }

    function onError() {
      router.go(-1)
    }

    commitMutation(environment, {
      mutation,
      onCompleted,
      onError,
      variables,
    })
  }, [])

  return <LoadingSpinner message="Changing Tenant" />
}
